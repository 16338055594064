import { CompositeContainer } from "components/CompositeContainer";
import { Informacao } from "components/Informacao";
import { Page } from "containers/Page";
import { BodyContainer } from "./style";
import {
  DadosProprietario,
  IDadosProprietario,
} from "./containers/DadosProprietario";
import { DadosVeiculo } from "./containers/DadosVeiculo";
import { DadosIpva } from "./containers/DadosIpva";
import { DadosLicenciamento } from "./containers/DadosLicenciamento";
import { DadosMultas, DadosMultasProps } from "./containers/DadosMultas";
import { DadosGravame, IDadosGravame } from "./containers/DadosGravame";
import {
  DadosRestricoes,
  IDadosRestricoes,
} from "./containers/DadosRestricoes";
import { DadosRecall, IDadosRecall } from "./containers/DadosRecall";
import { useExtratoVeicularDetalhado } from "./hooks/useExtratoVeicularDetalhado";
import { useParams } from "react-router-dom";
import { DadosLeiloes, IDadosLeiloes } from "./containers/DadosLeiloes";
import { DadosIpvaNacional } from "./containers/DadosIpvaNacional";
import { DadosLicenciamentoNacional } from "./containers/DadosLicenciamentoNacional";
import { DadosMultasNacional } from "./containers/DadosMultasNacional";
import { DadosDpvatNacional } from "./containers/DadosDpvatNacional";
import { DadosLeiloesNacionalSL } from "./containers/DadosLeiloesNacionalSL";
import { DadosRestricoesNacional } from "./containers/DadosRestricoesNacional";
import { FonteRetorno } from "features/extrato-veicular/enuns/FonteRetorno.enum";
import { useEffect } from "react";

export interface IDadosVeiculo {
  anoModelo: string;
  anoFabricacao: string;
  categoria: string;
  chassi: string;
  combustivel: string;
  cor: string;
  especie: string;
  marcaModelo: string;
  municipio: string;
  placa: string;
  renavam: string;
  situacao: string;
  nomeProprietario: string | null;
  cpfCnpjProprietario: string | null;
  codigoMotor: string;
  situacaoChassi: string;
  dataHoraCadastro: string;
}

export interface IDadosDebitos {
  descricao: string;
  valor: number;
}

export interface IDadosCombo {
  nome: string;
  fonteRetorno: string;
}

export interface IDetalhesExtratoVeicular {
  dadosProprietario: IDadosProprietario;
  dadosVeiculo: IDadosVeiculo[];
  ipvas: IDadosDebitos[];
  licenciamento: IDadosDebitos[];
  multas: DadosMultasProps;
  gravames: IDadosGravame[];
  bloqueios: IDadosRestricoes[];
  recalls: IDadosRecall[];
  leiloes: IDadosLeiloes;
  combo: IDadosCombo;
  dpvats: any[];
}

export function ExtratoVeicularDetalhado({
  mostrarHeader = true,
  redirect = true,
  mostrarLogo = true,
  serviceRequisicao = "consulta",
  setError,
}: {
  mostrarHeader?: boolean;
  redirect?: boolean;
  mostrarLogo?: boolean;
  serviceRequisicao?: "consulta" | "consultaIntegracao";
  setError?: (error: boolean) => void;
}) {
  const { idExtratoVeicular, idLoteExtratoVeicular } = useParams();

  const { detalhesExtratoVeicular, loading, error } =
    useExtratoVeicularDetalhado(
      idExtratoVeicular,
      idLoteExtratoVeicular,
      redirect,
      serviceRequisicao
    );
  useEffect(() => {
    if (error && setError) {
      setError(error);
    }
  }, [error]);
  return (
    <Page
      title="Extrato veicular"
      loading={loading}
      mostrarHeader={mostrarHeader}
    >
      {loading ? (
        <div></div>
      ) : (
        <CompositeContainer.Root>
          <CompositeContainer.Body style={{ padding: "1rem 1.5rem" }}>
            <Informacao
              mensagem="Esta consulta tem caráter apenas informativo, não podendo ser utilizada como certidão"
              showBotaoFechar={false}
              showLabel={false}
              type="atencao"
            />
            <BodyContainer>
              {detalhesExtratoVeicular && (
                <>
                  <DadosProprietario
                    detalhesExtratoVeicular={detalhesExtratoVeicular}
                    mostrarLogo={mostrarLogo}
                  />
                  <DadosVeiculo
                    dadosVeiculo={detalhesExtratoVeicular.dadosVeiculo[0]}
                  />
                  {[FonteRetorno.EXTRATO_PR, FonteRetorno.DEBITO_NACIONAL].includes(detalhesExtratoVeicular.combo.fonteRetorno) && (
                    <>
                      <DadosIpva ipvas={detalhesExtratoVeicular.ipvas} />
                      <DadosLicenciamento
                        licenciamentos={detalhesExtratoVeicular.licenciamento}
                      />
                      <DadosMultas
                        multas={detalhesExtratoVeicular.multas.multas}
                        total={detalhesExtratoVeicular.multas.total}
                        autuacoes={detalhesExtratoVeicular.multas.autuacoes}
                        totalAutuacoes={
                          detalhesExtratoVeicular.multas.totalAutuacoes
                        }
                      />
                      <DadosGravame
                        gravames={detalhesExtratoVeicular.gravames}
                      />
                      <DadosRestricoes
                        restricoes={detalhesExtratoVeicular.bloqueios}
                      />
                    </>
                  )}
                  {detalhesExtratoVeicular.combo.fonteRetorno ===
                    FonteRetorno.EXTRATO_NACIONAL && (
                    <>
                      <DadosIpvaNacional
                        ipvas={detalhesExtratoVeicular.ipvas}
                      />
                      <DadosLicenciamentoNacional
                        licenciamentos={detalhesExtratoVeicular.licenciamento}
                      />
                      <DadosDpvatNacional
                        dpvats={detalhesExtratoVeicular?.dpvats}
                      />
                      <DadosMultasNacional
                        multas={detalhesExtratoVeicular.multas.multas}
                        total={detalhesExtratoVeicular.multas.total}
                      />
                      <DadosRestricoesNacional
                        restricoes={detalhesExtratoVeicular.bloqueios}
                      />
                    </>
                  )}
                  <DadosRecall recalls={detalhesExtratoVeicular.recalls} />
                  {!detalhesExtratoVeicular.leiloes ? (
                    <DadosLeiloesNacionalSL />
                  ) : (
                    <DadosLeiloes leiloes={detalhesExtratoVeicular.leiloes} />
                  )}
                </>
              )}
            </BodyContainer>
          </CompositeContainer.Body>
        </CompositeContainer.Root>
      )}
    </Page>
  );
}
