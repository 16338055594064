import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import temaPadrao from "_config/temas/estilo/base";

export function InformacaopPDF() {
    return (
        <View style={{
            backgroundColor: temaPadrao.colors.warning.bgColor,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '4px',
            marginBottom: '8px'
        }}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_CLARO]}>
                Esta consulta tem caráter apenas informativo, não podendo ser utilizada como certidão
            </Text>
        </View>
    )
}