import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { useMask } from "hooks/useMask";

interface Props {
  nomeProprietario: string | null;
  cpfCnpjProprietario: string | null;
}

export function DadosProprietarioPDF({ cpfCnpjProprietario, nomeProprietario }: Readonly<Props>) {
  const {
    mCPFCNPJ,
  } = useMask();

  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Dados do proprietário</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>CPF/CNPJ</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{cpfCnpjProprietario ? mCPFCNPJ(cpfCnpjProprietario) : "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Nome</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{nomeProprietario ? nomeProprietario.toLocaleUpperCase() : "-"}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

