import axios, {
  AxiosHeaders,
  InternalAxiosRequestConfig
} from "axios";
import { getTokenIntegracao } from "../auth";

const msConsultaIntegracao = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MS_CONSULTA,
});

msConsultaIntegracao.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = getTokenIntegracao();
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        ...config.headers,
      } as unknown as AxiosHeaders;
      config.headers = headers;
    }

    return config;
  }
);

export default msConsultaIntegracao;
