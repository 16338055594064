import { intlCurrencyFormat } from "_utils/Money";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { BodyMultas } from "./containers/BodyMultas";
import { Stronger } from "components/Stronger";
import { TextoClaro } from "components/TextoClaro";

export interface IMultas {
  processoderecerusoobrigatorias: IDetalhesMultas[];
  dividaativaexecjudicialsobjudice: IDetalhesMultas[];
  obrigatorias: IDetalhesMultas[];
  impostas: IDetalhesMultas[];
  processoderecerusonaoobrigatorias: IDetalhesMultas[];
}

export interface IAutuacoes {
  notificadas: IDetalhesMultas[];
  processodedefesa: IDetalhesMultas[];
  atuacaoemprocessodenotificacao: IDetalhesMultas[];
}

export interface IDetalhesMultas {
  valor: number;
  descricaoArtigo: string;
  dataInfracao: string;
  horaInfracao: string;
  autoInfracao: string;
  dataVencimento: string;
  situacao: string;
  endereco: string;
  orgao: string;
  valorOriginal: number;
  valorComDesconto: number;
  valorJuro: number;
}

export interface DadosMultasProps {
  total: number;
  totalAutuacoes: number;
  autuacoes: IAutuacoes | null;
  multas: IMultas | null;
}

export function DadosMultas({ multas, total, autuacoes, totalAutuacoes }: Readonly<DadosMultasProps>) {
  function handleNaoExisteDebitos(dados: any) {
    const temDebitos = Object.keys(dados).some((key) => Array.isArray(dados[key]) && dados[key].length > 0);

    return temDebitos;
  }

  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={total > 0 || totalAutuacoes > 0 ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="Multas/Autuações" />
      </Section.Header>
      <Section.Body>
        <Section.Row>
          <Section.Col lg="7" md="7" sm="7">
            <span>Multas</span> <br />
            <Stronger>{total > 0 ? "Existe débito de Multas" : "Não existe débito de Multas"}</Stronger>
          </Section.Col>
          <Section.Col>
            <span>Valor total de multas</span> <br />
            <Stronger>{total > 0 ? intlCurrencyFormat(total) : "-"}</Stronger>
          </Section.Col>
        </Section.Row>
        {multas && handleNaoExisteDebitos(multas) && (
          <>
            <Section.Row>
              <Section.Col lg="7" md="7" sm="7" style={{ margin: 0 }}>
                <TextoClaro>Descrição</TextoClaro>
              </Section.Col>
              <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                <TextoClaro>Quantidade</TextoClaro>
              </Section.Col>
              <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                <TextoClaro>Valor total</TextoClaro>
              </Section.Col>
            </Section.Row>
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                    Multas em processo de recurso obrigatórias
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {multas.processoderecerusoobrigatorias?.length ?? 0}
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {intlCurrencyFormat(multas.processoderecerusoobrigatorias ?
                      multas.processoderecerusoobrigatorias?.map((multa) => multa.valorComDesconto ? multa.valorComDesconto : multa.valor + multa.valorJuro)
                        .reduce((acc, cur) => acc + cur / 100, 0) : 0
                    )}
                  </Section.Col>
                </>
              }
              toggleContent={
                multas.processoderecerusoobrigatorias?.length ? (
                  <BodyMultas detalhes={multas.processoderecerusoobrigatorias} />
                ) : null
              }
            />
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                    Multas em dívida ativa / exec. judicial / sob judice
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {multas.dividaativaexecjudicialsobjudice?.length ?? 0}
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {intlCurrencyFormat(multas.dividaativaexecjudicialsobjudice ?
                      multas.dividaativaexecjudicialsobjudice?.map((multa) => multa.valorComDesconto ? multa.valorComDesconto : multa.valor + multa.valorJuro)
                        .reduce((acc, cur) => acc + cur / 100, 0) : 0
                    )}
                  </Section.Col>
                </>
              }
              toggleContent={
                multas.dividaativaexecjudicialsobjudice?.length ? (
                  <BodyMultas detalhes={multas.dividaativaexecjudicialsobjudice} />
                ) : null
              }
            />
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                    Multas obrigatórias
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {multas.obrigatorias?.length ?? 0}
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {intlCurrencyFormat(multas.obrigatorias ?
                      multas.obrigatorias?.map((multa) => multa.valorComDesconto ? multa.valorComDesconto : multa.valor + multa.valorJuro)
                        .reduce((acc, cur) => acc + cur / 100, 0) : 0
                    )}
                  </Section.Col>
                </>
              }
              toggleContent={multas.obrigatorias?.length ? <BodyMultas detalhes={multas.obrigatorias} /> : null}
            />
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                    Multas em processo de recurso não obrigatórias
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {multas.processoderecerusonaoobrigatorias?.length ?? 0}
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {intlCurrencyFormat(multas.processoderecerusonaoobrigatorias ?
                      multas.processoderecerusonaoobrigatorias?.map((multa) => multa.valorComDesconto ? multa.valorComDesconto : multa.valor + multa.valorJuro)
                        .reduce((acc, cur) => acc + cur / 100, 0) : 0
                    )}
                  </Section.Col>
                </>
              }
              toggleContent={
                multas.processoderecerusonaoobrigatorias?.length ? (
                  <BodyMultas detalhes={multas.processoderecerusonaoobrigatorias} />
                ) : null
              }
            />
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                    Multas impostas
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {multas.impostas?.length ?? 0}
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {intlCurrencyFormat(multas.impostas ?
                      multas.impostas?.map((multa) => multa.valorComDesconto ? multa.valorComDesconto : multa.valor + multa.valorJuro)
                        .reduce((acc, cur) => acc + cur / 100, 0) : 0
                    )}
                  </Section.Col>
                </>
              }
              toggleContent={multas.impostas?.length ? <BodyMultas detalhes={multas.impostas} /> : null}
            />
          </>
        )}
        <Section.Row style={{ marginTop: "2rem" }}>
          <Section.Col lg="7" md="7" sm="7">
            <span>Autuações</span> <br />
            <Stronger>{totalAutuacoes > 0 ? "Existe débito de Autuações" : "Não existe débito de Autuações"}</Stronger>
          </Section.Col>
          <Section.Col>
            <span>Valor total de autuações</span> <br />
            <Stronger>{totalAutuacoes > 0 ? intlCurrencyFormat(totalAutuacoes) : "-"}</Stronger>
          </Section.Col>
        </Section.Row>
        {autuacoes && handleNaoExisteDebitos(autuacoes) && (
          <>
            <Section.Row>
              <Section.Col lg="7" md="7" sm="7" style={{ margin: 0 }}>
                <TextoClaro>Descrição</TextoClaro>
              </Section.Col>
              <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                <TextoClaro>Quantidade</TextoClaro>
              </Section.Col>
              <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                <TextoClaro>Valor total</TextoClaro>
              </Section.Col>
            </Section.Row>
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                    Autuações notificadas
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {autuacoes.notificadas.length}
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {intlCurrencyFormat(autuacoes.notificadas ?
                      autuacoes.notificadas
                        .map((autuacao) => (autuacao.valorComDesconto ? autuacao.valorComDesconto : autuacao.valor))
                        .reduce((acc, cur) => acc + cur / 100, 0) : 0
                    )}
                  </Section.Col>
                </>
              }
              toggleContent={autuacoes.notificadas.length ? <BodyMultas detalhes={autuacoes.notificadas} /> : null}
            />
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                    Autuações em processo de defesa
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {autuacoes.processodedefesa.length}
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {intlCurrencyFormat(autuacoes.processodedefesa ?
                      autuacoes.processodedefesa
                        .map((autuacao) => (autuacao.valorComDesconto ? autuacao.valorComDesconto : autuacao.valor))
                        .reduce((acc, cur) => acc + cur / 100, 0) : 0
                    )}
                  </Section.Col>
                </>
              }
              toggleContent={
                autuacoes.processodedefesa.length ? <BodyMultas detalhes={autuacoes.processodedefesa} /> : null
              }
            />
            <Section.RowToggle
              rowContent={
                <>
                  <Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
                    Autuações em processo de notificação
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {autuacoes.atuacaoemprocessodenotificacao.length}
                  </Section.Col>
                  <Section.Col lg="2" md="2" sm="2" style={{ margin: 0 }}>
                    {intlCurrencyFormat(autuacoes.atuacaoemprocessodenotificacao ?
                      autuacoes.atuacaoemprocessodenotificacao
                        .map((autuacao) => (autuacao.valorComDesconto ? autuacao.valorComDesconto : autuacao.valor))
                        .reduce((acc, cur) => acc + cur / 100, 0) : 0
                    )}
                  </Section.Col>
                </>
              }
              toggleContent={
                autuacoes.atuacaoemprocessodenotificacao.length ? (
                  <BodyMultas detalhes={autuacoes.atuacaoemprocessodenotificacao} />
                ) : null
              }
            />
          </>
        )}
      </Section.Body>
    </Section.Root>
  );
}

