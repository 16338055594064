import { Header } from "components/Header";
import { LoadingCirculo } from "components/LoadingCirculo";
import { LoadingScreen } from "./style";

interface Props {
  title: string;
  children: any;
  loading?: boolean;
  lastPage?: string;
  redirectDoIcone?: string;
  mostrarHeader?: boolean;
}

export function Page({
  title,
  children,
  loading,
  lastPage,
  redirectDoIcone,
  mostrarHeader = true,
}: Props) {
  return (
    <div>
      {loading ? (
        <LoadingScreen>
          <LoadingCirculo mostrar={loading} />
        </LoadingScreen>
      ) : (
        ""
      )}
      {mostrarHeader && (
        <Header
          title={title}
          lastPage={lastPage}
          redirectDoIcone={redirectDoIcone}
        />
      )}

      {children}
    </div>
  );
}
