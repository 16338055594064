import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData } from "_services/api/interface/HttpResponse";
import msConsulta from "_services/api/ms_consulta";
import msConsultaIntegracao from "_services/api/ms_consulta_integracao";
import msFinanceiro from "_services/api/ms_financeiro";
import msUsuarioLw from "_services/api/ms_usuariolw";
import { AxiosRequestConfig, HttpStatusCode } from "axios";
import { toastErro } from "components/Toast";
import { useCallback, useEffect, useState } from "react";

type service =
  | "api"
  | "consulta"
  | "usuarioLw"
  | "financeiro"
  | "consultaIntegracao";

export function useRequest(service: service) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (error?.hasErro && Array.isArray(error?.data?.message)) {
      toastErro(error?.data?.message.join(", \n"));
    }

    if (error?.hasErro && typeof error?.data?.message === "string") {
      toastErro(error?.data?.message);
    }

    setError(null);
  }, [error, setError]);

  const axiosStrategy = useCallback((service: service) => {
    const instancias = {
      api: api,
      consulta: msConsulta,
      consultaIntegracao: msConsultaIntegracao,
      usuarioLw: msUsuarioLw,
      financeiro: msFinanceiro,
    };

    return instancias[service];
  }, []);

  const get = useCallback(
    async (
      url: string,
      headers: AxiosRequestConfig = {},
      showErrors: boolean = true
    ) => {
      setLoading(true);
      try {
        const { data, status } = await axiosStrategy(service).get(url, headers);
        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error) {
        const response = new HttpError<IData>(true, error);
        if (showErrors && response.status !== HttpStatusCode.NotFound) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [axiosStrategy, service]
  );

  const post = useCallback(
    async <T, Y>(
      url: string,
      body: T,
      headers: AxiosRequestConfig = {},
      showErrors: boolean = true
    ) => {
      try {
        setLoading(true);
        const { data, status } = await axiosStrategy(service).post(
          url,
          body,
          headers
        );
        const response = new HttpResponse<Y>(false, status, data);
        setError(response);
        return response;
      } catch (error) {
        const response = new HttpError<IData>(true, error);
        if (showErrors) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [axiosStrategy, service]
  );

  const patch = useCallback(
    async <T>(
      url: string,
      body: T,
      headers: AxiosRequestConfig = {},
      showErrors: boolean = true
    ) => {
      try {
        setLoading(true);
        const { data, status } = await axiosStrategy(service).patch(
          url,
          body,
          headers
        );
        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error) {
        const response = new HttpError<IData>(true, error);
        if (showErrors) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [axiosStrategy, service]
  );

  return {
    get,
    post,
    patch,
    setError,
    loading,
    error,
  };
}
