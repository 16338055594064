import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import { Required } from "components/Required";
import { Container } from "./style";
import { Col, Row } from "react-bootstrap";
import { BodyModalExtratoVeicularProps } from "../BodyLote";
import { useMaskInput } from "hooks/useMaskInput";
import { useState } from "react";

export function BodyIndividual({
  combosConsulta,
  control,
  controller: Controller,
  errors,
  isSubmitted,
}: Readonly<BodyModalExtratoVeicularProps>) {
  const { mInputPlaca, mInputRenavam, mInputChassi } = useMaskInput();
  const [desabilitarRenavamChassi, setDesabilitarRenavamChassi] = useState<boolean>(false);
  const handleDisabled = (tipoConsulta: string) => {
    const somentePlaca = ["PRE_VENDA_NACIONAL", "PRE_VENDA_NACIONAL_SL", "DEBITO_NACIONAL"]
    if (somentePlaca.includes(tipoConsulta)) {
      setDesabilitarRenavamChassi(true);
      return;
    }
    setDesabilitarRenavamChassi(false);
  }

  return (
    <Container>
      <Row style={{ width: "100%" }}>
        <Col lg="3">
          <Controller
            control={control}
            name="tipoConsulta"
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                options={combosConsulta}
                onSelect={(e) => {
                  onChange(e)
                  handleDisabled(e)
                }}
                selectedValue={value}
                required
                title="Tipo de consulta"
                mensagemErro={errors?.tipoConsulta?.message}
                disabled={!combosConsulta.length}
              />
            )}
          />
        </Col>
        <Col lg="2">
          <label>
            Placa <Required />
          </label>
          <Controller
            control={control}
            name="placa"
            render={({ field: { onChange, value } }) => (
              <CustomInput
                placeholder="Informe a placa"
                isInvalid={errors?.placa}
                isValid={isSubmitted && !errors?.placa}
                value={value}
                onChange={(event) => onChange(mInputPlaca(event))}
                mensagemErro={errors?.placa?.message}
              />
            )}
          />
        </Col>
        <Col lg="2">
          <label>
            Renavam {!desabilitarRenavamChassi && <Required />}
          </label>
          <Controller
            control={control}
            name="renavam"
            render={({ field: { onChange, value } }) => (
              <CustomInput
                placeholder="Informe o Renavam"
                isInvalid={errors?.renavam}
                isValid={isSubmitted && !errors?.renavam}
                onChange={(event) => onChange(mInputRenavam(event))}
                value={value}
                mensagemErro={errors?.renavam?.message}
                disabled={desabilitarRenavamChassi}
              />
            )}
          />
        </Col>
        <Col lg="3">
          <label>
            Chassi {!desabilitarRenavamChassi && <Required />}
          </label>
          <Controller
            control={control}
            name="chassi"
            render={({ field: { onChange, value } }) => (
              <CustomInput
                placeholder="Informe o Chassi"
                isInvalid={errors?.chassi}
                isValid={isSubmitted && !errors?.chassi}
                onChange={(event) => onChange(mInputChassi(event))}
                value={value}
                mensagemErro={errors?.chassi?.message}
                disabled={desabilitarRenavamChassi}
              />
            )}
          />
        </Col>
      </Row>
    </Container>
  );
}

