import { useCallback, useEffect, useState } from "react";
import { useRequest } from "hooks/useRequest";
import { toastErro } from "components/Toast";
import { useNavigate } from "react-router-dom";

export function useExtratoVeicularDetalhado(
  idExtratoVeicular: string | undefined,
  idLoteExtratoVeicular: string | undefined,
  redirect: boolean = true,
  serviceRequisicao: "consulta" | "consultaIntegracao" = "consulta"
) {
  const [detalhesExtratoVeicular, setDetalhesExtratoVeicular] =
    useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const { loading, get } = useRequest(serviceRequisicao);
  const navigate = useNavigate();

  const buscarDetalhesExtratoVeicular = useCallback(async () => {
    const { data, hasErro } = await get(
      `veiculo/extrato/${idLoteExtratoVeicular}/detalhes/${idExtratoVeicular}`
    );

    if (hasErro) {
      setError(hasErro);
      toastErro("Não foi possível acessar os detalhes");

      if (redirect) {
        navigate(`/veiculos/extrato/consultas/${idLoteExtratoVeicular}`);
      }
      return;
    }

    setDetalhesExtratoVeicular(data);
  }, [idExtratoVeicular, get, navigate, idLoteExtratoVeicular]);

  useEffect(() => {
    buscarDetalhesExtratoVeicular();
  }, [buscarDetalhesExtratoVeicular]);

  return {
    detalhesExtratoVeicular,
    loading,
    error,
  };
}
