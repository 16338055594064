export const TOKEN_KEY = "@pagamento_debito_Token";
export const NOME_KEY = "@nome_usuario";
export const EMAIL_KEY = "@email";
export const EMPRESA_KEY = "@empresa_usuario";
export const USUARIO_ADMIN_KEY = "@usuario_admin";

function isAuthenticated() {
  return getToken() !== null ? true : false;
}

function getToken(): string | null {
  return localStorage.getItem(TOKEN_KEY);
}
export function getTokenIntegracao(): string | null {
  return localStorage.getItem(`${TOKEN_KEY}_integracao`);
}

export function setToken(token: string) {
  return localStorage.setItem(TOKEN_KEY, token);
}

export function setTokenIntegracao(token: string) {
  return localStorage.setItem(`${TOKEN_KEY}_integracao`, token);
}

function removeToken() {
  return localStorage.clear();
}

function getNome() {
  const nomeArray = localStorage.getItem(NOME_KEY)?.split(" ");

  if (nomeArray && nomeArray?.length >= 2) {
    const primeiroNome = nomeArray?.shift();
    const sobrenome = nomeArray?.pop();
    return `${primeiroNome} ${sobrenome}`;
  }

  return localStorage.getItem(NOME_KEY);
}

function setNome(nome: string) {
  return localStorage.setItem(NOME_KEY, nome);
}

function getEmail() {
  return localStorage.getItem(EMAIL_KEY);
}

function setEmail(email: string) {
  return localStorage.setItem(EMAIL_KEY, email);
}

function setEmpresa(empresa: string) {
  return localStorage.setItem(EMPRESA_KEY, empresa);
}

function getEmpresa() {
  return localStorage.getItem(EMPRESA_KEY);
}

function setAdmin(admin: string) {
  return localStorage.setItem(EMPRESA_KEY, admin);
}

function getAdmin() {
  return localStorage.getItem(USUARIO_ADMIN_KEY);
}

function setLocalStore(
  token: string,
  nome: string,
  email: string,
  empresa: string
) {
  setToken(token);
  setNome(nome);
  setEmail(email);
  setEmpresa(empresa);
}

function parseJwt() {
  let token = getToken();

  if (!token) {
    return false;
  }

  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function verificarPermissao(permissao: string) {
  const token = parseJwt();

  if (!token.resource_access["debito-anual"]) {
    return false;
  }

  const grupo = token.resource_access["debito-anual"].roles.find(
    (item: string) => item === permissao
  );

  if (!grupo) {
    return false;
  }

  return true;
}

export {
  isAuthenticated,
  getToken,
  getNome,
  getEmail,
  setLocalStore,
  removeToken,
  getEmpresa,
  setEmpresa,
  getAdmin,
  parseJwt,
  verificarPermissao,
};
